import React, { useMemo, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLinesVertical, aUpload, faDownload, faFolder, faFolderOpen, faEdit, faTrashAlt, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import "./DataViewer.css";
import axios from "axios";
import ActionButton from "./actions/ActionButton";
import { useAuth } from "../provider/authProvider";
import { useData } from "../provider/dataProvider";
import { useTCP } from "../provider/TCPProvider";
import Waiting from "./Waiting";
import ModalContent from "./actions/ModalContent";
import { transformToListOfObjects } from "../utils/listFiles";
import { convertSize } from "../utils/convertSize";
import { convertDate } from "../utils/convertDate";
import { log, LogLevel } from './../utils/Logger';
import { get_prefixgroup } from './../pages/Storage';
import { postDataDir } from "../api/apiTCP";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";

function DataViewer({ selectedGroup = "Personal Files" }) {

  const [expanded, setExpanded] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);

  const [filters, setFilters] = useState({ name: '', size: '', modified: '' });
  const [sort, setSort] = useState({ column: null, direction: 1 });

  const [selectedItem, setSelectedItem] = useState(null);
  const [action, setAction] = useState(1)

  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState();
  const [modalName, setModalName] = useState();

  const [nomRepertoire, setNomRepertoire] = useState('');
  const [afficherChampNomRepertoire, setAfficherChampNomRepertoire] = useState(false);

  const { data, loadingData, updateData } = useData();
  const [isLoading, setLoading] = useState(true);
  const [gdata, setGData] = useState({ name: 'root', children: [] })
  const [inputList, setInputList] = useState([]);
  const { token } = useAuth();
  const { TCPUrl } = useTCP();

  function IconRepeated({ count }) {
    return (Array.from({ length: count - 2 }, (_, index) => (
      <span style={{ marginLeft: 20, color: "gray" }}>
      </span>)
    ));
  }

  function pathwithoutgroup(name) {
    return name.split('@')[1] || name
  }


  const toggleModal = () => {
    setModal(!modal);
  };


  useEffect(() => {
    if (data) {
      setGData(transformToListOfObjects(data[selectedGroup]))
    }
  }, [loadingData, action]);


  useEffect(() => {
    if (data) {
      setLoading(false)
      setGData(transformToListOfObjects(data[selectedGroup]))
    }
  }, [data, selectedGroup]);


  // New directory function 
  const creerRepertoire = (e) => {
    log(LogLevel.INFO, "Création du répertoire :", nomRepertoire);
    if (nomRepertoire.includes(' ')) { alert('Invalid space in folder name') }
    else {
      const newfolderuri = get_prefixgroup(selectedGroup) + nomRepertoire
      postDataDir(TCPUrl, token, newfolderuri, handleActions)
    }
    setAfficherChampNomRepertoire(false)
  };

  const handleInputChange = (e) => {
    setNomRepertoire(e.target.value);
  };

  const handleClick = () => {
    setAfficherChampNomRepertoire(true);
  };
  //--------------------------------------------------//


  const handleActions = () => {
    updateData()
    setAction(action + 1)
  };

  // Drag and Drop functions ------------------------//
  const handleDragStart = (event, item) => {
    event.dataTransfer.setData('text/plain', ''); // Firefox requires this to enable drag
    setSelectedItem(item);
  };

  const handleDrop = (event, targetItem) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      setModalAction = "upload"

      handleUploadFiles(files, targetItem);
    } else if (selectedItem !== null) {
      handleMoveFiles(selectedItem, targetItem)
      log(LogLevel.INFO, selectedItem, ' to ', targetItem)
    }
  };

  const handleUploadFiles = (files, name) => {
    log(LogLevel.INFO, files.path, name.path);
  };

  const handleMoveFiles = (node, name) => {
    var src = node.path
    var dest = name.path
    if (src.endsWith('/')) {
      dest = name.path + node.name + '/';
    }

    const body = { "src": src, 'dest': dest };
    /*  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.post('https://api.thecrossproduct.xyz/v1/data/move',JSON.stringify(body), {headers: {
        'Content-Type': 'application/json'
      }})
        .then((response) => {
          if (response.status >= 200 && response.status <300 ) {
            handleActions()  
          } else {
           log(LogLevel.INFO,'Erreur de requête. Statut :', response.status);
           }
       })
      .catch(error => {
      log(LogLevel.ERROR,'Une erreur s\'est produite :', error);
      });*/
    handleActions();
    log(LogLevel.INFO, "Body", body)
    log(LogLevel.INFO, node.path, name.path);
  };


  const handleDragOver = event => {
    event.preventDefault();
  };
  //-----------------------------------------------------//


  // Toggle function 
  const toggleNode = name => {
    log(LogLevel.INFO, "Expanded :", expanded)
    setExpanded({
      ...expanded,
      [name]: !expanded[name]
    });
  };
  //-----------------------------------------------------//

  // CheckItems Functions ----------------------------------//
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allItems = getAllItems(gdata);
      setSelectedItems(allItems);
    } else {
      setSelectedItems([]);
    }
  };

  const getAllItems = node => {
    let items = [node.name];
    if (node.children) {
      node.children.forEach(child => {
        items = items.concat(getAllItems(child));
      });
    }
    return items;
  };

  const handleCheckboxChange = (event, name) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, name]);
    } else {
      setSelectedItems(selectedItems.filter(item => item !== name));
    }
  };
  //-----------------------------------------------------------//


  // Mousse on a ligne -----------------------------------------//
  const handleMouseEnter = name => {
    setHoveredItem(name);
  };
  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
  //-----------------------------------------------------------//

  const handleFilterChange = (event, column) => {
    setFilters({
      ...filters,
      [column]: event.target.value
    });
  };


  const handleSort = column => {
    setSort(prevSort => ({
      column,
      direction: prevSort.column === column ? -prevSort.direction : 1
    }));
  };


  const applyFilters = node => {
    const { name, size, modified } = filters;
    if (
      node.name && node.name.toLowerCase().includes(name.toLowerCase()) &&
      (node.size || '').toLowerCase().includes(size.toLowerCase()) &&
      (node.modified || '').toLowerCase().includes(modified.toLowerCase())
    ) {
      return true;
    }
    if (node.children) {
      return node.children.some(applyFilters);
    }
    return false;
  };

  const sortData = (data, column, direction) => {
    if (column === 'name') {
      return data.sort((a, b) => {
        return direction * a.name.localeCompare(b.name);
      });
    } else if (column === 'size') {
      return data.sort((a, b) => {
        return direction * (parseInt(a.size) - parseInt(b.size));
      });
    } else if (column === 'modified') {
      return data.sort((a, b) => {
        const modifiedA = a.modified || '';
        const modifiedB = b.modified || '';
        return direction * modifiedA.localeCompare(modifiedB);
      });
    }
    return data;
  };

  const renderTree = (node, level) => {
    if (!applyFilters(node)) {
      return null;
    }
    const sortedChildren = sortData(node.children || [], sort.column, sort.direction);

    return (
      <>
      
        {level > 1 && (
      <tr key={get_prefixgroup(selectedGroup) + node.path}
          //  onMouseEnter={() => handleMouseEnter(node.name)} onMouseLeave={handleMouseLeave} onDragStart={e => handleDragStart(e, node)} onDrop={event => handleDrop(event, node)}
          //  onDragOver={handleDragOver}
          //  draggables
          style={{ cursor: 'pointer' }}>
          {
            /* REMOVE CHECKBOX <td>
            <input
              type="checkbox"
              checked={selectedItems.includes(node.
                path)}
              onChange={e => handleCheckboxChange(e, node.path)}
            />

              </td>*/}
          
          <td style={{ paddingLeft: 20, cursor: 'pointer', marginRight: '5px' }} onClick={() => toggleNode(node.path)}>
            <IconRepeated count={level} />
            {node.children ? (
              <span style={{ marginRight: '5px' }} className="folder-icon">
                <FontAwesomeIcon icon={expanded[node.path] ? faFolderOpen : faFolder} />
              </span>
            ) :
              (<span className="file-icon"></span>)
            }
            {node.name.split('@')[1] || node.name}


          </td>
          <td>{convertSize(node.size) || '-'}</td>
          <td>{convertDate(node.modified) || '-'}</td>
          <td>
            <div className="action-icons">
              {/*hoveredItem === node.name && (
              <ActionButton name={node.path} isDir={node.isDir} handleActions={handleActions}/>
          )*/}
              <ActionButton groupname={selectedGroup} name={get_prefixgroup(selectedGroup) + pathwithoutgroup(node.path)} isDir={node.isDir} handleActions={handleActions} />
            </div>
          </td>
        </tr>)}
        {(level < 2 || expanded[node.path]) &&
          node.children &&
          node.children.map(childNode =>  renderTree(childNode, level + 1))}
      </>
    );
  };


  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  log(LogLevel.INFO, "Render DataViewer, selectedGroup : ", selectedGroup)
  return (
    <>

      {modal && (
        <ModalContent groupname={selectedGroup} toggleModal={toggleModal} action={modalAction} name={modalName} handleAction={handleActions} />
      )}
      <div className="file-tree-container">
        {/*  <div className="filters">
        <input
          type="text"
          placeholder="Filtrer par nom"
          value={filters.name}
          onChange={e => handleFilterChange(e, 'name')}
        />
      </div>*/
        }

        <table className="file-tree-table">
          <thead>
            <tr>
              {/* REMOVE CHECKBOX <th style={{ width: "10px" }}>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                />
      </th>*/}
              <th onClick={() => handleSort('name')}>
                Name{' '}
                {sort.column === 'name' && (
                  <FontAwesomeIcon icon={sort.direction === 1 ? faSort : sort.direction === -1 ? faSortUp : faSortDown} />
                )}
              </th>
              <th onClick={() => handleSort('size')}>
                Size{' '}
                {sort.column === 'size' && (
                  <FontAwesomeIcon icon={sort.direction === 1 ? faSort : sort.direction === -1 ? faSortUp : faSortDown} />
                )}
              </th>
              <th onClick={() => handleSort('modified')}>
                Modified{' '}
                {sort.column === 'modified' && (
                  <FontAwesomeIcon icon={sort.direction === 1 ? faSort : sort.direction === -1 ? faSortUp : faSortDown} />
                )}
              </th>
              <th style={{ width: "150px" }}> Actions</th>
            </tr>
          </thead>
          {isLoading ? <tbody><Waiting /></tbody> :
            <tbody>
            <><tr>
          
        {!afficherChampNomRepertoire ?<td>  
        <span onClick={() => handleClick()} data-testid="create-subfolder-button">
                <FontAwesomeIcon icon={faFolderPlus} />
              </span></td>: (
                <td>
          <div>
            <input
              type="text"
              value={nomRepertoire}
              onChange={handleInputChange}
              placeholder="new folder name"
            /><button onClick={creerRepertoire}>create</button>
          </div></td>
        )}
      <td></td><td></td><td></td>
          </tr></> 
              {(loadingData) ? <Waiting /> : renderTree(gdata, 0)}</tbody>}
        </table>
      </div>
    </>
  );

}

export default DataViewer;