

export const verifyToken = async (url,token, setToken) => {
  if (token) {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
      } else {
        setToken(null); // Invalidate the token if it's not valid
      }
    } catch (error) {
      setToken(null); // Invalidate the token if there's an error
    }
  }
};

export async function getTCP(url, token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Authorization': "Bearer " + token,
      'Content-Type': 'application/json'
    }
  };
  const response = await fetch(url, requestOptions)
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
    
  }
  const data = await response.json();
  return data;
}

export async function postTCP(url,token,body){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': "Bearer " + token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const response = await fetch(url, requestOptions)
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  return data;
}

export async function postDataDir(baseurl,token,foldername,handleActions){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': "Bearer " + token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(
      {'uri':foldername}
    )
  };
  const response = await fetch(baseurl + "/v1/data/dir", requestOptions)
  
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }else{handleActions();}
  return ;
}


export async function postData(baseurl,token,body){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': "Bearer " + token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(
      body
    )
  };
  const response = await fetch(baseurl+"/v1/data", requestOptions)
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  return ;
}
