import React, { useState, useEffect} from 'react';
import Waiting from "../components/Waiting";
import { useProc } from "../provider/procsProvider";
import { useLocation } from 'react-router-dom';
import { modifState } from '../components/DisplayTableProcesses';
import axios from 'axios';
import { LogLevel,log } from '../utils/Logger';
import { useAuth } from '../provider/authProvider';
import { useTCP } from '../provider/TCPProvider';
import { format } from 'date-fns';
import "./ProcessDetail.css"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
export default function ProcessDetail() {

  const {TCPApp} = useTCP();
  const {token} = useAuth();

  const [selectedKeys, setSelectedKeys] = useState([]);
  
  const {procs,loadingProc} = useProc();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('param1');
  const [outputs,setOutputs] = useState({});

  useEffect(()=>{ 
  console.log("USE EFFECT Details")
  const url = TCPApp + "/process/" + id;
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.get(url).then(response => {
    console.log(response)
        console.log(response.data["outputs"])
        setOutputs(response.data["outputs"])
      })
      .catch(error => {
        log(LogLevel.ERROR, 'Erreur de requête', error);
      });
      
    },[token]) 
  if (!Array.isArray(procs)) {
    return <div><Waiting/></div>;
  }



  const handleToggleSelectAll = () => {
    if (selectedKeys.length === Object.keys(outputs).length) {
      setSelectedKeys([]);
    } else {
      setSelectedKeys(Object.keys(outputs));
    }
  };

  const handleCheckboxChange = (key) => {
    setSelectedKeys((prevSelectedKeys) =>
      prevSelectedKeys.includes(key)
        ? prevSelectedKeys.filter((k) => k !== key)
        : [...prevSelectedKeys, key]
    );
  };


  const hasMultipleKeys = Object.keys(outputs).length > 1;

  const handleDownload = async () => {
    for (const key of selectedKeys) {
      console.log(key);
      const link = document.createElement('a');
      link.href = outputs[key];
      link.download = `file_${key}`; // Utilisez un nom de fichier unique
      document.body.appendChild(link);
  
      // Utilisez une promesse pour gérer le téléchargement de manière asynchrone
      await new Promise((resolve) => {
        link.addEventListener('click', () => {
          setTimeout(() => {
            document.body.removeChild(link);
            resolve();
          }, 100); // Attendez 100ms pour s'assurer que le téléchargement commence
        });
        link.click();
      });
    }
  };


  const item = procs.find(item => item.id === id);


  return (<>
    <Link to="/processes">
              <span style={{ color: "#fff", fontSize: '3em' }}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </span>
     </Link>
     <div data-testid="proc-page-component" style={{ padding: "30px", borderTopLeftRadius: "3px", backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
     
    <h2 className="results-title">Process : {id}</h2>
     <div className="results-container">
      <div className="results-card">
      
        <div className="results-item">
          <strong>id:</strong> {item.id}
        </div>
        <div className="results-item">
          <strong>Application:</strong> {item.app}
        </div>
        <div className="results-item">
          <strong>Domain:</strong> {item.domain}
        </div>
        <div className="results-item">
          <strong>Launched:</strong> {format(new Date(item.launched), 'dd MMMM yyyy')}
        </div>
        <div className="results-item">
          <strong>Terminated:</strong> {format(new Date(item.terminated), 'dd MMMM yyyy')}
        </div>
        <div className="results-item">
          <strong>Quote:</strong> {item.quote}
        </div>
        <div className="results-item">
          <strong>State:</strong> {modifState(item.state)}
        </div>
        <div className="results-item">
          <strong>Errors:</strong> {item.errors ? item.errors : 'None'}
        </div>
      </div>
    </div>

    <div className="results-container">
    <h2 className="results-title">Inputs</h2>
      {Object.entries(item.body.inputs).map(([key, values]) => (
        <div key={key}>
        <strong>{key}:</strong>
        <ul>
          {values.map((value, index) => (
            <li key={index}>{value}</li>
          ))}
        </ul>
      </div>
      ))}
    </div>

    {!(Object.keys(outputs).length == 0 )&&
    <div className="results-container">
    <h2 className="results-title">Outputs</h2>
{hasMultipleKeys ? (
        <div style={{ marginBottom: '10px' }}>
         <p> <input
            type="checkbox"
            checked={selectedKeys.length === Object.keys(outputs).length}
            onChange={handleToggleSelectAll}
          /><strong>select all</strong> </p>
        </div>
      ): <> </>
      }
      {Object.keys(outputs).map((key) => (
                 <div key={key} style={{ marginBottom: '10px' }}>
                 <input
                   type="checkbox"
                   checked={selectedKeys.includes(key)}
                   onChange={() => handleCheckboxChange(key)}
                 />
                   {key}
                 
               </div>
             ))}
             
               <div>
            
                 <button onClick={handleDownload}>
                  Download 
                 </button>
               </div>
            
           </div>}
     </div>
     </>
    )
  }


   {/* <p><strong>Outputs:</strong> {JSON.stringify(outputs)}</p>
       <p><strong>Endpoint:</strong> {item.endpoint}</p>
      <p><strong>Agent:</strong> {item.agent}</p>
      <p><strong>Tags:</strong> {item.tags}</p>
      <p><strong>Quote:</strong> {item.quote}</p>
      <p><strong>Pool:</strong> {JSON.stringify(item.body.pool)}</p>
      <p><strong>Output Prefix:</strong> {item.body['output-prefix']}</p>
       */}