import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./authProvider";
import { log, LogLevel } from '../utils/Logger';
import { useTCP } from "./TCPProvider";
import { getTCP } from "../api/apiTCP";

const prodContext = createContext();

const ProdProvider = ({ children }) => {

  const { token } = useAuth()
  const { TCPApp } = useTCP()

  const [products, setProducts] = useState({})
  const [loadingProd, setLoadingProd] = useState(true);

  const updateProd = () => {
    setLoadingProd(true)
    getTCP(TCPApp, token)
      .then((resp) => {
        setProducts(resp)
        setLoadingProd(false)
      })
      .catch(error => {
        log(LogLevel.ERROR, error.message)
        setLoadingProd(false)
      })
  }

  useEffect(() => {
    if (token) {
      updateProd()
    }
  }, [token]);


  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      products,
      loadingProd,
      updateProd,
      setProducts,
    }),
    [products, loadingProd]
  );

  // Provide the authentication context to the children components
  return (
    <prodContext.Provider value={contextValue}>{children}</prodContext.Provider>
  );
};

export const useProduct = () => {
  return useContext(prodContext);
};

export default ProdProvider;
